import { Sheet } from '@salutejs/plasma-ui';
import { capitalFirstLetter, textCropper } from '@/utils/string';
import { FC, useEffect, useState } from 'react';
import { PostCardEntity, useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';
import { Icon } from '@salutejs/plasma-icons';
import { IconTextWrapper } from '@/components/Icons';

interface HistorySheetProps {
  changePrompt: (value: string) => void;
}

const HistorySheet: FC<HistorySheetProps> = ({ changePrompt }) => {
  const [promptHistory, setPromptHistory] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data } = useGetPostCardsQuery({
    variables: {
      input: {
        skip: 0,
        limit: 100,
        isMy: true
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.getPostCards) {
      const newHints = getPromptHistory(data.getPostCards as PostCardEntity[]);
      setPromptHistory(newHints);
    }
  }, [data]);

  const getPromptHistory = (data: PostCardEntity[]): string[] => {
    if (data) {
      return data.map((item: PostCardEntity) => item.prompt);
    }
    return [];
  };

  const switchOpenHistory = () => {
    setIsOpen(!isOpen);
  };

  const setNewPrompt = (prompt: string) => {
    changePrompt(prompt);
    setIsOpen(false);
  };

  return (
    <>
      {!!promptHistory.length && (
        <IconTextWrapper onClick={switchOpenHistory}>
          <Icon icon="clock" />
        </IconTextWrapper>
      )}
      {
        <Sheet
          style={{ bottom: '80px' }}
          isOpen={isOpen}
          withOverlay={true}
          withTransition={true}
          onClose={() => setIsOpen(false)}
        >
          <h3>История запросов</h3>
          <div
            style={{
              position: 'absolute',
              bottom: '-80px',
              width: '100%',
              left: 0,
              backgroundColor: 'var(--plasma-colors-background-primary, #171717)',
              height: '80px'
            }}
          ></div>
          <ul className="prompt-list">
            {promptHistory.map((prompt, index) => (
              <li className="prompt-item" key={index} onClick={() => setNewPrompt(prompt)}>
                {capitalFirstLetter(textCropper(prompt, 50))}
              </li>
            ))}
          </ul>
          <style>{`
                .prompt-list {
                    list-style-type: none;
                    padding: 0;
                    max-height: 300px;
                }

                .prompt-item {
                    background-color: #282828;
                    border-radius: 16px;
                    color: white;
                    padding: 10px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            `}</style>
        </Sheet>
      }
    </>
  );
};

export default HistorySheet;
