import { useEffect, useState } from 'react';

const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const isSberbox = window.navigator.userAgent.toLowerCase().includes('sberbox');
    const isSmallScreen = window.innerWidth < 560;
    return isSmallScreen && !isSberbox;
  });

  useEffect(() => {
    const handleResize = () => {
      const isSberbox = window.navigator.userAgent.toLowerCase().includes('sberbox');
      const isSmallScreen = window.innerWidth < 560;
      setIsMobile(isSmallScreen && !isSberbox);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile, isDesktop: !isMobile };
};

export default useDeviceType;
