import React, { useEffect, useRef } from 'react';
import { Config } from '@/components/generate/StyleGenerateSelectorDesktop';
import { styleImageConfigs } from '@/components/configs/styleConfigs';
import CardStyleWrapper from '@/components/wrappers/CardStyleWrapper';
import { useGenerateStore } from '@/store/generate';
import { Icon } from '@salutejs/plasma-icons';
import { Col, Row } from '@salutejs/plasma-ui';

const SelectStyle = () => {
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { setImgStyle, imgStyle, setShowStyleSelect } = useGenerateStore((state) => ({
    imgStyle: state.imgStyle,
    setShowStyleSelect: state.setShowStyleSelect,
    setImgStyle: state.setImgStyle
  }));

  useEffect(() => {
    const selectedIndex = styleImageConfigs.findIndex(
      (config) => config.styleName === imgStyle.styleName
    );
    const indexToFocus = selectedIndex >= 0 ? selectedIndex : 0;

    // Установка фокуса
    cardRefs.current[indexToFocus]?.focus();
  }, [imgStyle]);

  const handleStyleSelection = (config: Config) => {
    setImgStyle(config);
    setShowStyleSelect(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent, config: Config) => {
    if (event.key === 'Enter') {
      handleStyleSelection(config);
      setShowStyleSelect(false);
    }
  };

  return (
    <>
      <Row style={{ width: '100%', padding: '35px' }}>
        {styleImageConfigs.map((config, index) => (
          <Col
            key={config.styleName}
            size={2}
            style={{ position: 'relative', marginBottom: '10px' }}
          >
            <div
              ref={(el) => (cardRefs.current[index] = el)}
              onKeyDown={(e) => handleKeyDown(e, config)}
            >
              <CardStyleWrapper
                title={config.title}
                image={config.image || ''}
                onClick={() => handleStyleSelection(config)}
                isSelected={imgStyle.styleName === config.styleName}
              >
                {config.icon && <Icon icon={config.icon} color={'rgba(255, 255, 255, 0.56)'} />}
              </CardStyleWrapper>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SelectStyle;
