import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Button, CarouselGridWrapper, Col, Row } from '@salutejs/plasma-ui';
import PromptAssistant from '@/components/generate/PromptAssistant';
import ActionButtons from '@/components/ActionButtons';
import useImageManager from '@/hooks/useImageManager';
import CustomTextArea from '@/components/CustomTextArea';
import { AssistantContext } from '@/providers/AssistantProvider';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import BtnShare from '@/components/BtnShare';
import BtnPublish from '@/components/BtnPublish';
import { useGenerateStore } from '@/store/generate';
import { usePostCardStore } from '@/store/postCard';
import { useMain } from '@/store/main';
import useDeviceType from '@/hooks/useDeviceType';
import GenerateScreenDesktop from '@/components/generateDesktop/GenerateScreenDesktop';
import FloatingBottomWrapper from '@/components/wrappers/FloatingBottomWrapper';
import StyleGenerateSelectorDesktop from '@/components/generate/StyleGenerateSelectorDesktop';
import TitleBlock from '@/components/TitleBlock';
import StyleGenerateSelectorMobile from '@/components/generate/StyleGenerateSelectorMobile';
import FullScreenImage from '@/components/FullScreenImage';
import { useNavigate } from 'react-router-dom';

const GeneratePage = () => {
  const [isGenerateCommand, setIsGenerateCommand] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const navigate = useNavigate();

  const { isMobile, isDesktop } = useDeviceType();
  const { setTitle, setIcon } = useMain((state) => ({
    setTitle: state.setTitle,
    setIcon: state.setIcon
  }));

  const {
    edit,
    setEdit,
    prompt,
    setPrompt,
    postCards,
    currentPostCardIndex,
    setCurrentCardIndex,
    nextCard,
    clearAll,
    previousCard
  } = useGenerateStore((state) => ({
    edit: state.edit,
    setEdit: state.setEdit,
    currentPostCardIndex: state.currentPostCardIndex,
    setCurrentCardIndex: state.setCurrentCardIndex,
    prompt: state.prompt,
    postCards: state.postCards,
    setPrompt: state.setPrompt,
    nextCard: state.nextCard,
    previousCard: state.previousCard,
    clearAll: state.clearAll
  }));

  const { postCard, setPostCard } = usePostCardStore((state) => ({
    setPostCard: state.setPostCard,
    postCard: state.postCard
  }));

  const { generate } = useImageManager();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const startGenerate = async () => {
    setEdit(false);
    if (!prompt && postCard) setPrompt(postCard.prompt);
    if (prompt) await generate();
  };

  const { generatePhrase } = useContext(AssistantContext);

  useEffect(() => {
    return () => {
      clearAll();
    };
  }, []);

  useEffect(() => {
    if (generatePhrase) {
      setPrompt(generatePhrase.image);
      setIsGenerateCommand(true);
    }
  }, [generatePhrase]);

  useEffect(() => {
    if (isGenerateCommand) {
      startGenerate();
      setIsGenerateCommand(false);
    }
  }, [isGenerateCommand]);

  useEffect(() => {
    setIcon('');
    setTitle('');
    if (isMobile && !postCard?.image?.imageUrl && !prompt) {
      setTitle('Создание рисунка');
    }
    if (isDesktop && !postCard?.image?.imageUrl && !prompt) {
      setTitle('Создание запроса');
    }

    return () => {
      setTitle('Kandinsky');
      setIcon('/logo.png');
    };
  }, [postCards, prompt, isDesktop, isMobile]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('modal') === 'open') {
      setShowFullScreen(true);
    } else {
      setShowFullScreen(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (postCards[currentPostCardIndex]) {
      setPostCard(postCards[currentPostCardIndex]);
      return;
    }
    setPostCard(postCards[postCards.length - 1]);
    setCurrentCardIndex(postCards.length - 1);
  }, [currentPostCardIndex, postCards]);

  if (!isMobile && !prompt) {
    return <GenerateScreenDesktop />;
  }

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('modal');
    navigate(
      `${location.pathname.replace(/\/$/, '')}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
    );
  };

  const handleOpenFullScreen = () => {
    setShowFullScreen(true);
    navigate(`${location.pathname}/?modal=open`);
  };

  return (
    <>
      <Row>
        {postCard && (
          <Col sizeS={4} sizeM={6} sizeL={4} sizeXL={5}>
            <div onClick={handleOpenFullScreen} style={{ marginBottom: '14px' }}>
              <ImageWitchPoetry key={postCard.id} postCard={postCard} />
            </div>
          </Col>
        )}

        <Col
          sizeS={postCard ? 4 : 8}
          sizeM={6}
          sizeL={postCard ? 4 : 8}
          sizeXL={postCard ? 5 : 12}
          offsetXL={1}
        >
          <CustomTextArea
            disabled={!edit}
            loading={!postCard?.image?.imageUrl}
            value={prompt}
            onChange={handleInputChange}
            onEnterPress={() => setEdit(false)}
            onEdit={() => setEdit(true)}
            maxLength={140}
          />
          {edit && isMobile && (
            <CarouselGridWrapper>
              <PromptAssistant promptCurrent={prompt} changePrompt={setPrompt} />
            </CarouselGridWrapper>
          )}
          {isMobile && (
            <>
              <CarouselGridWrapper>
                <TitleBlock>СТИЛЬ</TitleBlock>
                <StyleGenerateSelectorMobile disable={!edit && !postCard?.image?.imageUrl} />
              </CarouselGridWrapper>
            </>
          )}
          {isDesktop && <StyleGenerateSelectorDesktop />}

          {isDesktop && currentPostCardIndex >= 0 && (
            <>
              <ActionButtons
                style={{ margin: '0.5rem 0px' }}
                onCancel={previousCard}
                onRetry={startGenerate}
                onRepeat={nextCard}
                disableCancel={currentPostCardIndex === 0}
                disableRetry={!edit && !postCard?.image?.imageUrl}
                disableNext={postCards.length === currentPostCardIndex + 1}
              />
              <div style={{ margin: '14px 0' }}>
                <BtnShare />
              </div>
              <BtnPublish redirectHome />
            </>
          )}
          {isMobile && currentPostCardIndex >= 0 && (
            <>
              <ActionButtons
                style={{ margin: '0.5rem 0px' }}
                onCancel={previousCard}
                onRetry={startGenerate}
                onRepeat={nextCard}
                disableCancel={currentPostCardIndex === 0}
                disableRetry={!edit && !postCard?.image?.imageUrl}
                disableNext={postCards.length === currentPostCardIndex + 1}
              />
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  // marginLeft: '5px',
                  width: 'calc(100vw - var(--plasma-grid-margin) * 2)',
                  position: 'fixed',
                  bottom: '80px'
                }}
              >
                {postCard?.image?.imageUrl && (
                  <>
                    <BtnShare />
                    <BtnPublish
                      text="Опубликовать"
                      style={{
                        minWidth: '50%',
                        opacity: 1,
                        background: '#21A038',
                        color: 'white',
                        marginTop: '8px'
                      }}
                    />
                  </>
                )}
              </div>
            </>
          )}

          {!isMobile && currentPostCardIndex === -1 && (
            <Button
              scaleOnInteraction={false}
              disabled={!prompt.length}
              text={'Сгенерировать'}
              onTouchStart={prompt.length ? startGenerate : () => ''}
              onClick={prompt.length ? startGenerate : () => ''}
              view={'primary'}
              style={{ width: '100%' }}
            />
          )}
        </Col>
      </Row>
      {isMobile && currentPostCardIndex === -1 && (
        <FloatingBottomWrapper>
          <Button
            scaleOnInteraction={false}
            disabled={!prompt.length}
            text={'Сгенерировать'}
            onTouchStart={prompt.length ? startGenerate : () => ''}
            onClick={prompt.length ? startGenerate : () => ''}
            view={'primary'}
            style={{ width: 'calc(100vw - var(--plasma-grid-margin) * 2)' }}
          />
        </FloatingBottomWrapper>
      )}
      {showFullScreen && postCard?.image?.imageUrl && (
        <FullScreenImage src={postCard.image.imageUrl} onClose={handleCloseFullScreen} />
      )}
    </>
  );
};

export default GeneratePage;
