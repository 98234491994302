import React, { FC } from 'react';
import Gallery, { Filter } from '@/components/Gallery';
import TitleBlock from '@/components/TitleBlock';
import { CarouselGridWrapper } from '@salutejs/plasma-ui';
import { useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';

interface GalleryUserProps {
  filter: Filter;
}
const GalleryUser: FC<GalleryUserProps> = ({ filter }) => {
  const { data } = useGetPostCardsQuery({
    variables: {
      input: filter
    },
    fetchPolicy: 'network-only'
  });

  return (
    <>
      {!!data?.getPostCards?.length && (
        <CarouselGridWrapper>
          <TitleBlock>{filter.isMyLike ? 'Избранное' : 'Созданные'}</TitleBlock>
        </CarouselGridWrapper>
      )}
      <Gallery
        filter={filter}
        // children={<TitleBlock hasMargin={true}>{isMyLike ? 'Избранное' : 'Мои изображения'}</TitleBlock>}
      />
    </>
  );
};

export default GalleryUser;
