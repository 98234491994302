import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Container } from '@salutejs/plasma-ui';

interface Props {
  children: React.ReactNode;
}

const AppLayout: FC<Props> = ({ children }) => {
  return (
    // <Box
    //     display="flex"
    //     flexDirection="column"
    //     alignItems="center"
    //     width="100%"
    //     maxWidth="720px"
    //     minWidth="320px"
    //     margin="0 auto"
    //     overflow="hidden"
    // >
    //     <Box px={4} width="100%">
    <Container>{children}</Container>

    //     </Box>
    // </Box>
  );
};

export default AppLayout;
