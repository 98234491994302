import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { BtnLeft, BtnRefresh, BtnRight } from '@/components/Icons';
import { Button } from '@salutejs/plasma-ui';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  z-index: 5;
`;

const ButtonLabel = styled.div<{ disabled: boolean }>`
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  color: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.56)')};

  @media screen and (min-width: 960px) {
    font-size: 18px;
  }
`;

interface CircleButtonProps {
  children: ReactNode;
  label: string;
  disabled: boolean;
  onClick: () => void;
}

const CircleButton: FC<CircleButtonProps> = ({ children, label, disabled }) => {
  return (
    <div style={{ textAlign: 'center', marginRight: '18px' }}>
      {children}
      <ButtonLabel disabled={disabled} style={{ fontSize: '1rem' }}>
        {label}
      </ButtonLabel>
    </div>
  );
};

interface ActionButtonsProps {
  onCancel: () => void;
  onRetry: () => void;
  onRepeat: () => void;
  disableCancel?: boolean;
  disableRetry?: boolean;
  disableNext?: boolean;
  style?: CSSProperties;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  onCancel,
  onRetry,
  onRepeat,
  disableCancel = false,
  disableRetry = false,
  disableNext = false,
  style
}) => {
  return (
    <ButtonContainer style={style}>
      <CircleButton label="Предыдущее" disabled={disableCancel} onClick={onCancel}>
        <Button
          scaleOnInteraction={false}
          contentLeft={<BtnLeft />}
          pin="circle-circle"
          disabled={disableCancel}
          onClick={onCancel}
        />
      </CircleButton>
      <CircleButton label="Ещё вариант" disabled={disableRetry} onClick={onRetry}>
        <Button
          scaleOnInteraction={false}
          contentLeft={<BtnRefresh />}
          pin="circle-circle"
          disabled={disableRetry}
          onClick={onRetry}
        />
      </CircleButton>
      <CircleButton label="Следующее" disabled={disableNext} onClick={onRepeat}>
        <Button
          scaleOnInteraction={false}
          contentLeft={<BtnRight />}
          pin="circle-circle"
          disabled={disableNext}
          onClick={onRepeat}
        />
      </CircleButton>
    </ButtonContainer>
  );
};

export default ActionButtons;
