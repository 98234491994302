import { create } from 'zustand';
import { CreatePostcardMutation } from '@/graphql/graphQlApiHooks';

type PostCardState = {
  postCard: CreatePostcardMutation['createPostcard'] | undefined;
  setPostCard: (value: CreatePostcardMutation['createPostcard']) => void;
  clearPostCard: () => void;
};

export const usePostCardStore = create<PostCardState>((set) => ({
  postCard: undefined,
  setPostCard: (value) => set({ postCard: value }),
  clearPostCard: () => set({ postCard: undefined })
}));
