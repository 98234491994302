import { useEffect, useState } from 'react';
import { Button, CarouselGridWrapper, Col, Row } from '@salutejs/plasma-ui';
import { useNavigate } from 'react-router-dom';
import { useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';
import { useMain } from '@/store/main';

const HelpSliderComponentDesktop = () => {
  const [image, setImage] = useState<string>('images/gallery/01.png');

  const navigate = useNavigate();
  const { data, loading } = useGetPostCardsQuery({
    variables: {
      input: { skip: 0, limit: 2, isMy: true }
    },
    fetchPolicy: 'network-only'
  });

  const { showHelpSliderComponent, setHideForShowHelpSliderComponent } = useMain((state) => ({
    setHideForShowHelpSliderComponent: state.setHideForShowHelpSliderComponent,
    showHelpSliderComponent: state.showHelpSliderComponent
  }));

  const handleClick = () => {
    setHideForShowHelpSliderComponent();
    navigate('/generate');
  };

  const openHelp = () => {
    navigate('/help');
  };

  useEffect(() => {
    let image = 1;
    setInterval(() => {
      image += 1;
      if (image === 4) image = 1;
      setImage(`images/gallery/0${image}.png`);
    }, 10 * 1000);
  }, []);

  if (loading || data?.getPostCards.length || !showHelpSliderComponent) return;

  return (
    <div style={{ position: 'fixed', top: 0, zIndex: 8, width: 'calc(100vw - 40px)', left: 0 }}>
      <CarouselGridWrapper style={{ borderRadius: '24px 24px 0 0' }}>
        <Row>
          <Col size={12}>
            <div
              style={{
                background: `linear-gradient(
                      180deg,
                  rgba(8, 8, 8, 0.00) 0%,
                  rgba(8, 8, 8, 0.01) 8.07%,
                  rgba(8, 8, 8, 0.02) 15.54%,
                  rgba(8, 8, 8, 0.05) 22.50%,
                  rgba(8, 8, 8, 0.08) 29.04%,
                  rgba(8, 8, 8, 0.13) 35.26%,
                  rgba(8, 8, 8, 0.18) 41.25%,
                  rgba(8, 8, 8, 0.25) 47.10%,
                  rgba(8, 8, 8, 0.32) 52.90%,
                  rgba(8, 8, 8, 0.40) 58.75%,
                  rgba(8, 8, 8, 0.48) 64.74%,
                  rgba(8, 8, 8, 0.58) 70.96%,
                  rgba(8, 8, 8, 0.67) 77.50%,
                  rgba(8, 8, 8, 0.78) 84.46%,
                  rgba(8, 8, 8, 0.89) 91.93%,
                  #080808 100%
                  ),
                  url(${image}) no-repeat center center / cover, 
                  lightgray`,
                height: 'calc(100vh - 40px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                alignItems: 'center',
                color: 'white',
                textAlign: 'center',
                padding: '20px'
              }}
            >
              <p
                style={{
                  fontSize: '32px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '36px'
                }}
              >
                Присоединяйтесь к глобальному движению. <br />
                Раскройте свой творческий <br />
                потенциал
              </p>
              <Button
                scaleOnInteraction={false}
                text={'Создать'}
                onClick={handleClick}
                view={'checked'}
                size="s"
                style={{ width: '350px', opacity: 1, marginBottom: '12px' }}
              />
              <Button
                scaleOnInteraction={false}
                text={'Как это работает?'}
                onClick={openHelp}
                size="s"
                style={{ width: '350px', opacity: 1 }}
              />
              <span
                onClick={openHelp}
                style={{
                  marginTop: '24px',
                  paddingBottom: '100px'
                }}
              ></span>
            </div>
          </Col>
        </Row>
      </CarouselGridWrapper>
    </div>
  );
};

export default HelpSliderComponentDesktop;
