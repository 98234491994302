import useDeviceType from '@/hooks/useDeviceType';
import HelpPageDesktop from '@/components/help/HelpPageDesktop';
import HelpPageMobile from '@/components/help/HelpPageMobile';

const HelpPage = () => {
  const { isMobile, isDesktop } = useDeviceType();

  return (
    <>
      {isDesktop && <HelpPageDesktop />}
      {isMobile && <HelpPageMobile />}
    </>
  );
};

export default HelpPage;
