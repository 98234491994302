import {
  Button,
  ButtonProps,
  CarouselGridWrapper,
  Image,
  withAutoFocus
} from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { GetPostCardsQuery, useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';
import HelpSliderComponentDesktop from '@/components/howDoesThisWork/HelpSliderComponentDesktop';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import FloatingBottomWrapper from '@/components/wrappers/FloatingBottomWrapper';
import TitleBlock from '@/components/TitleBlock';
import ScrollWrapper from '@/components/wrappers/ScrollWrapper';
import useDeviceType from '@/hooks/useDeviceType';
import HelpSliderComponentMobile from '@/components/howDoesThisWork/HelpSliderComponentMobile';
import { useMain } from '@/store/main';

const StyledImageWrapper = styled.div`
  position: relative;
  width: 46vw;
  height: 46vw;
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  margin-right: 12px;
`;

const StyleWrapper = styled.div`
  align-items: center;
  display: inline-flex;
`;

const GalleryUser = () => {
  const { isMobile, isDesktop } = useDeviceType();

  const navigate = useNavigate();

  const { showHelpSliderComponent } = useMain((state) => ({
    showHelpSliderComponent: state.showHelpSliderComponent
  }));

  const { data } = useGetPostCardsQuery({
    variables: {
      input: { skip: 0, limit: 2, isMy: true }
    },
    fetchPolicy: 'cache-and-network'
  });

  const openAllGeneration = () => {
    navigate('/all-generated');
  };

  const handleClick = () => {
    navigate('/generate');
  };

  const handleImageClick = (currentImage: GetPostCardsQuery['getPostCards'][0]) => {
    if (currentImage?.id) navigate(`${currentImage.id}`, { state: { currentImage } });
  };

  return (
    <>
      {isDesktop && <HelpSliderComponentDesktop />}
      {isMobile && <HelpSliderComponentMobile />}
      {isMobile && (!showHelpSliderComponent || !!data?.getPostCards.length) && (
        <>
          <FloatingBottomWrapper>
            <Button
              text={'Создать'}
              onClick={handleClick}
              view={'primary'}
              style={{ width: 'calc(100vw - var(--plasma-grid-margin) * 2)', opacity: 1 }}
            />
          </FloatingBottomWrapper>
          {!!data?.getPostCards?.length && (
            <CarouselGridWrapper>
              <TitleBlock>Созданные</TitleBlock>
              <ScrollWrapper>
                {data?.getPostCards.map((image, index) => (
                  <StyleWrapper key={image?.id}>
                    <StyledImageWrapper>
                      {
                        <div onClick={() => handleImageClick(image)}>
                          <ImageWitchPoetry isHeard={false} key={index} postCard={image} />
                        </div>
                      }
                    </StyledImageWrapper>
                  </StyleWrapper>
                ))}
                <StyleWrapper>
                  <StyledImageWrapper>
                    <div onClick={openAllGeneration}>
                      <Image
                        src="/images/openGenerate.png"
                        ratio={'1/1'}
                        base={'div'}
                        alt="картинка для примера фоном"
                        style={{ position: 'relative', borderRadius: 20 }}
                      />
                    </div>
                  </StyledImageWrapper>
                </StyleWrapper>
              </ScrollWrapper>
            </CarouselGridWrapper>
          )}
        </>
      )}
    </>
  );
};

export default GalleryUser;
