import { createGlobalStyle } from 'styled-components';
import { salutejs_sber__dark } from '@salutejs/plasma-tokens/themes';
import {
  text // Цвет текста
} from '@salutejs/plasma-tokens';

const DocumentStyle = createGlobalStyle`
  @font-face {
    font-family: 'SB Sans Text';
    src: url('../assets/fonts/SBSansText-Regular.woff') format('woff2'),
    url('../assets/fonts/SBSansText-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html:root {
    min-height: 100vh;
    color: ${text};
    background-color: rgb(8 8 8) !important;
    font-family: 'SB Sans Text', sans-serif;
  }
`;

const ThemeStyle = createGlobalStyle(salutejs_sber__dark);

export const GlobalStyle = () => (
  <>
    <DocumentStyle />
    <ThemeStyle />
  </>
);
