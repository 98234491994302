import styled from 'styled-components';
import { IconEdit } from '@salutejs/plasma-icons';
import { ChangeEvent, useRef, KeyboardEvent, FC, useEffect } from 'react';
import { Button, TextField } from '@salutejs/plasma-ui';
import { useGenerateStore } from '@/store/generate';

interface CustomTextAreaProps {
  value: string;
  disabled: boolean;
  loading: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEdit: () => void;
  onEnterPress?: () => void;
  maxLength?: number;
}

const TextAreaWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const CustomTextArea: FC<CustomTextAreaProps> = ({
  value,
  disabled,
  onChange,
  onEdit,
  onEnterPress,
  maxLength = 140
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const { loadingImage } = useGenerateStore((state) => ({
    loadingImage: state.loadingImage
  }));

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= maxLength) {
      onChange(event);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onEnterPress?.();
    }
  };

  return (
    <TextAreaWrapper>
      <TextField
        ref={inputRef}
        disabled={disabled}
        contentRight={
          !loadingImage && disabled ? (
            <Button
              scaleOnInteraction={false}
              contentLeft={<IconEdit />}
              pin="circle-circle"
              view={'clear'}
              size={'s'}
              onClick={onEdit}
            />
          ) : undefined
        }
        value={value}
        maxLength={140}
        label={'Запрос'}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        autoFocus={true}
      />
    </TextAreaWrapper>
  );
};

export default CustomTextArea;
