import { useState, useEffect, FC } from 'react';
import { useGenerateStore } from '@/store/generate';
import CardStyleWrapper from '@/components/wrappers/CardStyleWrapper';
import styled from 'styled-components';
import { Icon } from '@salutejs/plasma-icons';
import './history-sheet.css';
import { styleImageConfigs } from '@/components/configs/styleConfigs';

const StyleWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  margin-bottom: 12px;
`;

const StyleGenerateSelectorMobile: FC<{ disable: boolean }> = ({ disable }) => {
  const [configs, setConfigs] = useState(styleImageConfigs);

  const { imgStyle, setImgStyle } = useGenerateStore((state) => ({
    imgStyle: state.imgStyle,
    setImgStyle: state.setImgStyle
  }));

  useEffect(() => {
    setConfigs([...styleImageConfigs]);
  }, [styleImageConfigs]);

  return (
    <div className="scroll-container">
      <div className="scroll-container--content">
        <div className="scroll-container--content__inner">
          <StyleWrapper>
            {configs.map((config) => (
              <CardStyleWrapper
                key={config.styleName}
                title={config.title}
                image={config.image}
                onClick={() => {
                  if (!disable) {
                    setImgStyle(config);
                  }
                }}
                isSelected={imgStyle.styleName === config.styleName}
                style={{
                  opacity: disable ? 0.5 : 1,
                  pointerEvents: disable ? 'none' : 'auto'
                }}
              >
                {config.icon && <Icon icon={config.icon} color={'rgba(255, 255, 255, 0.56)'} />}
              </CardStyleWrapper>
            ))}
          </StyleWrapper>
        </div>
      </div>
    </div>
  );
};

export default StyleGenerateSelectorMobile;
