import React, { useContext, useEffect, useState } from 'react';
import { Button, CarouselGridWrapper, Col, Row } from '@salutejs/plasma-ui';
import Slider from '@/components/SlideDot';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled, { keyframes } from 'styled-components';
import { useMain } from '@/store/main';
import { IconDisclosureLeft, IconDisclosureRight } from '@salutejs/plasma-icons';
import { AssistantContext } from '@/providers/AssistantProvider';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const AnimatedDiv = styled.div`
  background: ${() => `linear-gradient(to bottom, #06081d, #070d3c)`};
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  color: white;
  text-align: center;
  animation: ${fadeIn} 1s ease-in-out;
  padding-top: 15vh;
`;

const Title = styled.div`
  color: white;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  margin: 1rem auto 1.5rem;
  animation: ${fadeIn} 0.5s ease-in-out;
  max-width: 40vw;
`;

const SubTitle = styled.div`
  color: rgba(255, 255, 255, 0.56);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
  animation: ${fadeIn} 0.5s ease-in-out;
  max-width: 50vw;
  margin: 1.5rem auto;
`;

type Slide = {
  title: string;
  subTitle: string;
  img: string;
};

const SLIDES: Slide[] = [
  {
    title: 'Начните с описания объекта и его деталей',
    subTitle:
      'Опишите, чтобы вы хотели сгенерировать. Например, рыжего кота, красный автомобиль или портрет девушки. Допишите через запятую характерную черту: добрые глаза, дорога или чёрные волосы',
    img: 'images/help/01.png'
  },
  {
    title: 'Затем добавьте описание фона',
    subTitle:
      'Например, на заднем фоне снежные горы; на заднем фоне город, закатное солнце; или на заднем фоне лес с цветами',
    img: 'images/help/02.png'
  },
  {
    title: 'Определите стиль',
    subTitle:
      'Выберите стиль из предложенных или добавьте описание стиля словами. Например, скриншот DVD из фильма, ретро аниме; поп-арт; или кинематографичный свет, детализация, 4K',
    img: 'images/help/03.png'
  }
];

const HelpPageDesktop = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [goToHome, setGoToHome] = useState(false);
  const navigate = useNavigate();
  const assistantInstance = useContext(AssistantContext);

  const { setHideForShowHelpSliderComponent, setTitle } = useMain((state) => ({
    setHideForShowHelpSliderComponent: state.setHideForShowHelpSliderComponent,
    setTitle: state.setTitle
  }));

  const nextSlide = () => {
    setActiveSlide((currentSlide) => {
      const nextSlide = currentSlide + 1 >= SLIDES.length ? 0 : currentSlide + 1;
      if (nextSlide === 0) {
        setGoToHome(true);
      }
      return nextSlide;
    });
  };

  const previousSlide = () => {
    setActiveSlide((currentSlide) => {
      return currentSlide - 1 < 0 ? 0 : currentSlide - 1;
    });
  };

  useEffect(() => {
    if (goToHome) {
      setHideForShowHelpSliderComponent();
      navigate('/generate', { replace: true });
    }
  }, [goToHome, navigate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          previousSlide();
          break;
        case 'ArrowRight':
          nextSlide();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeSlide]);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => previousSlide(),
    trackMouse: false
  });

  useEffect(() => {
    setTitle('Как это работает');
  }, []);

  useEffect(() => {
    assistantInstance.assistantRef?.current?.sendData({
      action: { action_id: 'START_HELP' }
    });
  }, []);

  return (
    <div {...handlers}>
      {SLIDES[activeSlide] && (
        <CarouselGridWrapper>
          <Row>
            <Col size={12}>
              <AnimatedDiv key={activeSlide}>
                <img src={SLIDES[activeSlide].img} alt="" style={{ width: '100%' }} />
                <Title key={SLIDES[activeSlide].title}>{SLIDES[activeSlide].title}</Title>
                <SubTitle key={SLIDES[activeSlide].subTitle}>
                  {SLIDES[activeSlide].subTitle}
                </SubTitle>
              </AnimatedDiv>
            </Col>
            <Col size={12}>
              <Slider slides={SLIDES.length} activeSlide={activeSlide} onChange={setActiveSlide} />
              {activeSlide !== 0 && (
                <div style={{ position: 'fixed', bottom: '50vh', left: '1rem' }}>
                  <Button
                    size="s"
                    view="clear"
                    contentLeft={<IconDisclosureLeft />}
                    onClick={previousSlide}
                  />
                </div>
              )}
              <div style={{ position: 'fixed', bottom: '50vh', right: '1rem' }}>
                <Button
                  size="s"
                  view="clear"
                  contentLeft={<IconDisclosureRight />}
                  onClick={nextSlide}
                />
              </div>
            </Col>
          </Row>
        </CarouselGridWrapper>
      )}
    </div>
  );
};

export default HelpPageDesktop;
