import { Config } from '@/components/generate/StyleGenerateSelectorDesktop';

export const styleImageConfigs: Config[] = [
  {
    title: 'Без стиля',
    icon: 'close',
    image: '/images/style/none.jpg',
    styleName: 'no_style'
  },
  {
    title: 'Портретное',
    image: '/images/style/portrait.jpg',
    styleName: 'portrait'
  },
  {
    title: '3D рендер',
    image: '/images/style/3d_render.jpeg',
    styleName: '3d_render'
  },
  {
    title: 'Мультфильм',
    image: '/images/style/cartoon.jpeg',
    styleName: 'cartoon'
  },
  {
    title: 'Карандашом',
    image: '/images/style/pencil_drawing.jpeg',
    styleName: 'pencil_drawing'
  },
  {
    title: 'Мозаика',
    image: '/images/style/mosaic.jpeg',
    styleName: 'mosaic'
  },
  {
    title: 'Иконопись',
    image: '/images/style/christian_icon.jpeg',
    styleName: 'christian_icon'
  },
  {
    title: 'Маслом',
    image: '/images/style/oil_painting.jpeg',
    styleName: 'oil_painting'
  },
  {
    title: 'Ренессанс',
    image: '/images/style/renaissance.jpeg',
    styleName: 'renaissance'
  },
  {
    title: 'Классицизм',
    image: '/images/style/classicism.jpeg',
    styleName: 'classicism'
  },
  {
    title: 'Хохлома',
    image: '/images/style/khokhloma.jpeg',
    styleName: 'khokhloma'
  },
  {
    title: 'Пикассо',
    image: '/images/style/picasso.jpeg',
    styleName: 'picasso'
  },
  {
    title: 'Малевич',
    image: '/images/style/malevich.jpeg',
    styleName: 'malevich'
  },
  {
    title: 'Гончарова',
    image: '/images/style/gonharova.jpeg',
    styleName: 'gonharova'
  },
  {
    title: 'Айвазовский',
    image: '/images/style/aivazovsky.jpeg',
    styleName: 'aivazovsky'
  },
  {
    title: 'Новый год',
    image: '/images/style/christmas.jpeg',
    styleName: 'christmas'
  },
  {
    title: 'Мультфильм',
    image: '/images/style/soviet_cartoon.jpeg',
    styleName: 'soviet_cartoon'
  },
  {
    title: 'Кандинский',
    image: '/images/style/kandinsky.jpeg',
    styleName: 'kandinsky'
  }
];
