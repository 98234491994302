import { create } from 'zustand';
import { Config } from '@/components/generate/StyleGenerateSelectorDesktop';
import { CreatePostcardMutation } from '@/graphql/graphQlApiHooks';
import { styleImageConfigs } from '@/components/configs/styleConfigs';

type GenerateState = {
  edit: boolean;
  setEdit: (value: boolean) => void;
  prompt: string;
  setPrompt: (value: string) => void;
  imgStyle: Config;
  setImgStyle: (value: Config) => void;
  currentPostCardIndex: number;
  setCurrentCardIndex: (id: number) => void;
  postCards: CreatePostcardMutation['createPostcard'][] | [];
  setPostCards: (value: CreatePostcardMutation['createPostcard']) => void;
  updateCardInPostCards: (updatedPostCard: CreatePostcardMutation['createPostcard']) => void;
  deleteCardInPostCards: (id: string) => void;
  clearAll: () => void;
  nextCard: () => void;
  previousCard: () => void;
  showStyleSelect: boolean;
  setShowStyleSelect: (id: boolean) => void;
  loadingImage: boolean;
  setLoadingImage: (status: boolean) => void;
};

export const useGenerateStore = create<GenerateState>((set, get) => ({
  edit: true,
  setEdit: (value) => set({ edit: value }),
  currentPostCardIndex: -1,
  setCurrentCardIndex: (value) => {
    set({ currentPostCardIndex: value });
    const currentImgStyle = get().postCards[get().currentPostCardIndex];
    if (currentImgStyle?.image?.style) {
      const style = styleImageConfigs.find(
        (styleImg) => styleImg.styleName === currentImgStyle?.image?.style
      );
      set({ imgStyle: style });
    }
  },
  prompt: '',
  setPrompt: (value) => {
    set({ prompt: value });
  },
  imgStyle: {
    title: 'Без стиля',
    icon: 'close',
    image: '',
    styleName: 'no_style'
  },
  setImgStyle: (value) => set({ imgStyle: value }),
  postCards: [],
  setPostCards: (newPostCard) => {
    const currentPostCards = get().postCards;
    set({ postCards: [...currentPostCards, newPostCard] });
  },
  updateCardInPostCards: (updatedPostCard) => {
    set((state) => ({
      postCards: state.postCards.map((card) =>
        card.id === updatedPostCard.id ? { ...card, ...updatedPostCard } : card
      )
    }));
  },
  deleteCardInPostCards: (id) =>
    set((state) => ({
      postCards: state.postCards.filter((card) => card.id !== id)
    })),
  clearAll: () =>
    set({
      postCards: [],
      edit: true,
      prompt: '',
      currentPostCardIndex: -1,
      imgStyle: {
        title: 'Без стиля',
        icon: 'close',
        styleName: 'no_style'
      }
    }),
  nextCard: () => {
    const currentIndex = get().currentPostCardIndex;
    if (currentIndex < get().postCards.length - 1) {
      get().setCurrentCardIndex(currentIndex + 1);
    }
  },
  previousCard: () => {
    const currentIndex = get().currentPostCardIndex;
    if (currentIndex > 0) {
      get().setCurrentCardIndex(currentIndex - 1);
    }
  },
  showStyleSelect: false,
  setShowStyleSelect: (status: boolean) => set({ showStyleSelect: status }),
  loadingImage: false,
  setLoadingImage: (status: boolean) => set({ loadingImage: status })
}));
