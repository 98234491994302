export const textCropper = (text: string, length = 10) => {
  let preparedHint = text;
  if (text.length > length) {
    preparedHint = text.slice(0, length) + '...';
  }

  return preparedHint;
};

export const capitalFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
