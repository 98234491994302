import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface StyledFloatingProps {
  right?: boolean;
  bottomOffset: string;
}

const StyledFloating = styled.div<StyledFloatingProps>`
  z-index: 99;
  max-width: 1040px;
  position: fixed;
  width: 100vw;
  height: 140px;
  background: linear-gradient(
    0deg,
    #080808 0%,
    rgba(8, 8, 8, 0.99) 8.07%,
    rgba(8, 8, 8, 0.98) 15.54%,
    rgba(8, 8, 8, 0.95) 22.5%,
    rgba(8, 8, 8, 0.92) 29.04%,
    rgba(8, 8, 8, 0.87) 35.26%,
    rgba(8, 8, 8, 0.82) 41.25%,
    rgba(8, 8, 8, 0.75) 47.1%,
    rgba(8, 8, 8, 0.68) 52.9%,
    rgba(8, 8, 8, 0.6) 58.75%,
    rgba(8, 8, 8, 0.52) 64.74%,
    rgba(8, 8, 8, 0.42) 70.96%,
    rgba(8, 8, 8, 0.33) 77.5%,
    rgba(8, 8, 8, 0.22) 84.46%,
    rgba(8, 8, 8, 0.11) 91.93%,
    rgba(8, 8, 8, 0) 100%
  );

  bottom: ${(props) => props.bottomOffset}
    ${(props) =>
      props.right &&
      `
    right: 16px;
  `};
  left: 0;
  text-align: center;
  padding-top: 60px;
}

  @media (min-width: 960px) {
    bottom: 120px;
  }
`;

interface FloatingButtonProps {
  children: ReactNode;
  right?: boolean;
}

// const getDeviceType = () => {
//   return window.navigator.userAgent.toLowerCase().includes('sberbox') ? 'sberBox' : 'mobile';
// };

const FloatingBottomWrapper: FC<FloatingButtonProps> = function FloatingBottomWrapper({
  children,
  right
}) {
  // const isFocused = useStore((state) => state.isFocused);
  // const deviceType = useMemo(getDeviceType, []);

  // const bottomOffset = deviceType === 'mobile' ? (isFocused ? '240px' : '0') : '0';

  return (
    <StyledFloating right={right} bottomOffset={'0'}>
      {children}
    </StyledFloating>
  );
};

export default FloatingBottomWrapper;
