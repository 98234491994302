import { useEffect, useState } from 'react';

const useCheckWitch = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onMoreWidth = (minWidth: number) => screenWidth >= minWidth;

  return { onMoreWidth };
};

export { useCheckWitch };
