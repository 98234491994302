import { create } from 'zustand';

type Main = {
  title: string;
  setTitle: (id: string) => void;
  icon: string;
  setIcon: (icon: string) => void;
  showHelpSliderComponent: boolean;
  setHideForShowHelpSliderComponent: () => void;
};

export const useMain = create<Main>((set) => ({
  title: 'Kandinsky',
  icon: '/logo.png',
  setIcon: (value) => set({ icon: value }),
  setTitle: (value) => set({ title: value }),
  showHelpSliderComponent: true,
  setHideForShowHelpSliderComponent: () => set({ showHelpSliderComponent: false })
}));
