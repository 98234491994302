import { useContext } from 'react';
import { AssistantContext, AssistantContextValue } from '@/providers/AssistantProvider';

const useAssistantContext = (): AssistantContextValue => {
  const context = useContext(AssistantContext);
  if (!context) {
    throw new Error('useAssistantContext must be used within an AssistantProvider');
  }
  return context;
};

export { useAssistantContext };
