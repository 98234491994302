import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const SquareBlock = styled.div<{ isTop: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 14px;
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto; /* Центрирование по горизонтали */
`;

interface SpinnerBlockWrapperProps {
  children: ReactNode;
  isTop?: boolean;
}

const SpinnerBlockWrapper: FC<SpinnerBlockWrapperProps> = ({ children, isTop = false }) => {
  return (
    <SquareBlock isTop={isTop}>
      <ContentContainer>{children}</ContentContainer>
    </SquareBlock>
  );
};

export default SpinnerBlockWrapper;
