import { IconDisclosureRight, IconName } from '@salutejs/plasma-icons';
import { StylesImg } from '@/hooks/useImageManager';
import './history-sheet.css';
import { useGenerateStore } from '@/store/generate';
import { Button, CellIcon, TextField } from '@salutejs/plasma-ui';
import SelectStyle from '@/pages/SelectStyle';
import { useEffect } from 'react';
import useDeviceType from '@/hooks/useDeviceType';
export interface Config {
  title: string;
  icon?: IconName;
  image: string;
  styleName: StylesImg;
}

const StyleGenerateSelectorDesktop = () => {
  const { isMobile } = useDeviceType();

  const { imgStyle, showStyleSelect, setShowStyleSelect, loadingImage } = useGenerateStore(
    (state) => ({
      imgStyle: state.imgStyle,
      showStyleSelect: state.showStyleSelect,
      loadingImage: state.loadingImage,
      setShowStyleSelect: state.setShowStyleSelect
    })
  );

  useEffect(() => {
    return setShowStyleSelect(false);
  }, []);

  return (
    <>
      <TextField
        disabled
        style={{ marginBottom: '12px' }}
        label="Стиль"
        value={imgStyle.title}
        contentRight={
          !loadingImage ? (
            <Button
              scaleOnInteraction={false}
              contentLeft={<IconDisclosureRight />}
              pin="circle-circle"
              view={'clear'}
              size={'s'}
              onClick={() => setShowStyleSelect(true)}
            />
          ) : undefined
        }
        contentLeft={
          imgStyle.image ? (
            <CellIcon
              as="img"
              src={imgStyle.image}
              alt={imgStyle.title}
              style={{ margin: '-15px' }}
            />
          ) : undefined
        }
      />
      {showStyleSelect && (
        <div className={`style-modal ${!isMobile ? 'non-mobile' : ''}`}>
          <SelectStyle />
        </div>
      )}
    </>
  );
};

export default StyleGenerateSelectorDesktop;
