import ReactDOM from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { SSRProvider } from '@salutejs/plasma-ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/assets/css/index.css';
import { AssistantProvider } from '@/providers/AssistantProvider';
import ApolloProviderChangeToken from '@/providers/ApolloProviderChangeToken';
import { BrowserRouter } from 'react-router-dom';
import './sentry';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <SSRProvider>
    <AssistantProvider>
      <ApolloProviderChangeToken>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProviderChangeToken>
    </AssistantProvider>
  </SSRProvider>
);
