import { FC } from 'react';
import { Spinner } from '@salutejs/plasma-ui';

const LoadingComponent: FC = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}
  >
    <Spinner size={33} />
  </div>
);

export default LoadingComponent;
