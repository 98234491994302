import { Button, HeaderContent } from '@salutejs/plasma-ui';
import { BtnGen, BtnList } from '@/components/Icons';
import { Icon } from '@salutejs/plasma-icons';
import { useNavigate } from 'react-router-dom';
import BtnDeleteDesktop from '@/components/delete/BtnDeleteDesktop';
import BtnHeartDesktop from '@/components/favorite/BtnHeartDesktop';
import { useCheckWitch } from '@/hooks/useCheckWitch';
import { useGenerateStore } from '@/store/generate';
import useDeviceType from '@/hooks/useDeviceType';
import { useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';
import LoadingComponent from '@/components/Loading';
import { useMain } from '@/store/main';
import { useEffect, useRef } from 'react';

export const UUID_REGEX =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

const HeaderMenuDesktop = () => {
  const { isDesktop, isMobile } = useDeviceType();
  const { onMoreWidth } = useCheckWitch();
  const focusButtonRef = useRef<HTMLButtonElement>(null);

  const { deleteCardInPostCards } = useGenerateStore((state) => ({
    deleteCardInPostCards: state.deleteCardInPostCards
  }));

  const { setHideForShowHelpSliderComponent, showHelpSliderComponent } = useMain((state) => ({
    setHideForShowHelpSliderComponent: state.setHideForShowHelpSliderComponent,
    showHelpSliderComponent: state.showHelpSliderComponent
  }));

  const { data, loading } = useGetPostCardsQuery({
    variables: {
      input: { skip: 0, limit: 2, isMy: true }
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    focusButtonRef?.current?.focus();
  }, []);

  const isFavorite = location.pathname === '/favorites';
  const isAllGenerate = location.pathname === '/all-generated';
  const isGenerate = location.pathname === '/generate';
  const isHome = location.pathname === '/';
  const isHelp = location.pathname === '/help';

  const navigate = useNavigate();

  const handleNavigate = (newPath: string) => {
    navigate(newPath, { replace: true });
    window.history.pushState({ page: 'page' }, '', newPath);
  };

  const handleFavoritesClick = () => handleNavigate('/favorites');
  const handleHomeClick = () => handleNavigate('/');
  const handleGenerateClick = () => {
    setHideForShowHelpSliderComponent();
    handleNavigate('/generate');
  };
  const handleAllGenerateClick = () => handleNavigate('/all-generated');

  const match = location.pathname.match(UUID_REGEX) || [];

  if (isDesktop && (match[0] || isGenerate)) {
    return (
      <>
        <BtnHeartDesktop />
        <BtnDeleteDesktop onClick={isGenerate ? deleteCardInPostCards : handleHomeClick} />
      </>
    );
  }

  if (loading) return <LoadingComponent />;

  if (isDesktop && (match[0] || isHelp)) {
    return <></>;
  }

  if (isMobile && isHelp) {
    return (
      <span onClick={handleGenerateClick} style={{ cursor: 'pointer', userSelect: 'none' }}>
        Пропустить
      </span>
    );
  }

  if (isMobile && isGenerate) {
    return (
      <>
        <BtnDeleteDesktop onClick={isGenerate ? deleteCardInPostCards : handleHomeClick} />
      </>
    );
  }

  if (isMobile && (isGenerate || isHelp)) {
    return <></>;
  }

  return (
    <>
      <HeaderContent>
        {isDesktop && (
          <>
            {!!data?.getPostCards.length && (
              <>
                <Button
                  scaleOnInteraction={false}
                  text={onMoreWidth(780) ? 'Лента' : ''}
                  onClick={!isHome ? handleHomeClick : () => ''}
                  style={{ marginLeft: '12px', background: isHome ? '' : '#fff0' }}
                  size="s"
                  contentLeft={<BtnList color={isHome ? 'white' : '#929292'} />}
                />

                <Button
                  text={onMoreWidth(780) ? 'Мои изображения' : ''}
                  scaleOnInteraction={false}
                  onClick={!isAllGenerate ? handleAllGenerateClick : () => ''}
                  style={{ marginLeft: '12px', background: isAllGenerate ? '' : '#fff0' }}
                  size="s"
                  contentLeft={<BtnGen color={isAllGenerate ? 'white' : '#929292'} />}
                />
              </>
            )}
          </>
        )}
        {!!data?.getPostCards.length && (
          <Button
            scaleOnInteraction={false}
            text={onMoreWidth(780) ? 'Избранное' : ''}
            onClick={!isFavorite ? handleFavoritesClick : () => ''}
            style={{ marginLeft: '12px', background: isFavorite ? '' : '#fff0' }}
            size="s"
            contentLeft={<Icon icon="heart" color={isFavorite ? 'white' : '#929292'} />}
          />
        )}
        {(!!data?.getPostCards.length || !showHelpSliderComponent) &&
          isDesktop &&
          onMoreWidth(420) && (
            <Button
              ref={focusButtonRef}
              autoFocus={isHome}
              style={{ marginLeft: '12px' }}
              scaleOnInteraction={false}
              className="sn-section-item"
              text={'Создать'}
              onClick={handleGenerateClick}
              view={'primary'}
              size="s"
            />
          )}
      </HeaderContent>
    </>
  );
};

export default HeaderMenuDesktop;
