import { FC, useState } from 'react';
import { CreatePostcardMutation, useDeletePostcardMutation } from '@/graphql/graphQlApiHooks';
import ConfirmationModal from '@/components/ConformationSheet';
import { Button, useToast } from '@salutejs/plasma-ui';
import { useCheckWitch } from '@/hooks/useCheckWitch';
import { Icon } from '@salutejs/plasma-icons';

interface BtnDeleteMobileProps {
  postCard: CreatePostcardMutation['createPostcard'] | undefined;
  onClick?: (id: string) => void;
}

const BtnDeleteMobile: FC<BtnDeleteMobileProps> = ({ postCard, onClick }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [postcard_Delete] = useDeletePostcardMutation();
  const { showToast } = useToast();
  const { onMoreWidth } = useCheckWitch();

  const handleDelete = () => {
    if (postCard?.id) {
      setConfirmationOpen(true);
    }
  };

  const handleConfirm = async () => {
    if (postCard?.id) {
      try {
        await postcard_Delete({
          variables: {
            input: { id: postCard.id }
          }
        });
        showToast({ text: 'Генерация успешно удалена', timeout: 1000 });
        onClick && onClick(postCard.id);
      } catch (e) {
        console.error(e);
      }
    }
    setConfirmationOpen(false);
  };

  const handleCancel = () => {
    setConfirmationOpen(false);
  };

  if (!postCard?.image?.imageUrl || !postCard?.isMy) return null;

  return (
    <>
      <div className={'btn-delete'}>
        <Button
          scaleOnInteraction={false}
          size="s"
          onClick={handleDelete}
          text={onMoreWidth(560) ? 'Удалить' : ''}
          contentLeft={<Icon icon={'trash'} />}
        />
      </div>
      <ConfirmationModal
        isOpen={confirmationOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={'Удалить эту генерацию?'}
        subtitle={'Данная публикация станет неактуальной'}
      />
    </>
  );
};

export default BtnDeleteMobile;
