import Gallery from '@/components/Gallery';
import { useMain } from '@/store/main';
import React, { useEffect } from 'react';
import GalleryUserTop from '@/components/GalleryUserTop';
import TitleBlock from '@/components/TitleBlock';
import { CarouselGridWrapper } from '@salutejs/plasma-ui';
import { useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const { setTitle } = useMain((state) => ({
    setTitle: state.setTitle
  }));

  const { data } = useGetPostCardsQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: { skip: 0, limit: 100, isPublic: true, order: { updatedAt: 'DESC' } }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setTitle('Kandinsky');
  }, []);

  useEffect(() => {
    navigate('/', { replace: true });
    window.history.pushState(null, '', '/');
  }, []);

  return (
    <>
      <GalleryUserTop />
      {!!data?.getPostCards?.length && (
        <CarouselGridWrapper>
          <TitleBlock>Лента сообщества</TitleBlock>
        </CarouselGridWrapper>
      )}
      <Gallery filter={{ skip: 0, limit: 100, isPublic: true }} />
    </>
  );
};

export default HomePage;
