import { FC, useEffect } from 'react';

interface FullScreenImageProps {
  src: string;
  onClose: () => void;
}

const FullScreenImage: FC<FullScreenImageProps> = ({ src, onClose }) => {
  const handleClick = () => {
    onClose();
  };

  return (
    <div
      onClick={handleClick}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.05) 100%), #080808',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img
        src={src}
        alt="Full Screen"
        onClick={handleClick}
        style={{ maxHeight: '90%', maxWidth: '90%' }}
      />
    </div>
  );
};

export default FullScreenImage;
