import { MouseEvent, useEffect, useState } from 'react';
import { Icon } from '@salutejs/plasma-icons';
import { Button, useToast } from '@salutejs/plasma-ui';
import { CreatePostcardMutation, useLikePostcardMutation } from '@/graphql/graphQlApiHooks';
import { BtnNoHeardMobile } from '@/components/Icons';
import { usePostCardStore } from '@/store/postCard';
import { useCheckWitch } from '@/hooks/useCheckWitch';
import { useGenerateStore } from '@/store/generate';
import styled from 'styled-components';

interface BtnHeartProps {
  postCard?: CreatePostcardMutation['createPostcard'] | undefined;
}

const StyledIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const BtnHeartMobile = ({ postCard: propPostCard }: BtnHeartProps) => {
  const { showToast } = useToast();
  const [isLiked, setIsLiked] = useState(false);
  const [likePostcard] = useLikePostcardMutation();
  const { onMoreWidth } = useCheckWitch();

  const storePostCard = usePostCardStore((state) => state.postCard);
  const { updateCardInPostCards } = useGenerateStore((state) => ({
    updateCardInPostCards: state.updateCardInPostCards
  }));

  const postCard = propPostCard || storePostCard;

  const isGenerate = location.pathname === '/generate';

  useEffect(() => {
    if (postCard) {
      setIsLiked(postCard.isLikedByMe);
    }
  }, [postCard]);

  if (isGenerate) return null;

  const setFavorite = async (id: string, isLike: boolean) => {
    const previousIsLiked = isLiked;
    if (postCard) updateCardInPostCards({ ...postCard, isLikedByMe: isLike });
    setIsLiked(isLike);
    try {
      await likePostcard({ variables: { input: { id, like: isLike } } });
    } catch (error) {
      setIsLiked(previousIsLiked);
      showToast({ text: 'Ошибка при изменении состояния избранного', timeout: 1000 });
      console.error('Ошибка при обновлении избранного:', error);
    }
  };

  const handleHeartClick = (event: MouseEvent) => {
    if (!postCard) return;

    event.stopPropagation();
    setFavorite(postCard?.id, !isLiked);

    showToast({
      text: isLiked ? 'Рисунок удален из избранного' : 'Рисунок добавлен в избранное',
      timeout: 1000
    });
  };

  if (!postCard?.image?.imageUrl) return null;

  const getTextBtn = () => {
    return isLiked ? 'Убрать из избранного' : 'Добавить в избранное';
  };

  return (
    <StyledIconWrapper>
      <div className={'btn-delete'} style={{ right: '13rem' }}>
        <Button
          scaleOnInteraction={false}
          size="s"
          onClick={handleHeartClick}
          text={onMoreWidth(560) ? getTextBtn() : ''}
          style={{ borderRadius: '40px', backgroundColor: '#171717' }}
          contentLeft={isLiked ? <BtnNoHeardMobile /> : <Icon icon={'heartStroke'} />}
        />
      </div>
    </StyledIconWrapper>
  );
};

export default BtnHeartMobile;
